import React from 'react';

const NavigationIcon = () => (
  <li>
    <a href="#home">
      <span>
        <p>LUIS</p>
        <p>MEZA</p>
      </span>
    </a>
  </li>
);

export default NavigationIcon;
